import React, {useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Container, Button} from 'react-bootstrap';
import VoiceInner from '../components/VoiceInner.js';
const Voice = () => {

  return (
    <div >
<VoiceInner />
    </div>
  );
}

export default Voice;
