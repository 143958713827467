import { useEffect, useState, useContext } from 'react';

function Voice() {

  return (
    <div>
    </div>
  );
}

export default Voice;
